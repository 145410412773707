// Defined roles, each corresponds to a number so we can easily compare
const RoleLevels = {
  "developer": 999,
  "admin": 900,
  "manager": 500,
  "employee": 400,
  "referral": 300,
  "user": 200,
}

// Main entry point for determining privledge
export default function hasRole (user, role, exactRole = false) {
  // First, check that the parameters are valid.
  if (!user || !user.role) return false; // If no user, return false
  if (!role) return true; // If no role, return true (for nav)
  if (!user.role) return false; // If no user role, return false

  // If we are looking for an 'exact' role, only return true if the desired role matches the user
  if (exactRole) return role === user.role;

  // Grab the active user's role level from RoleLevels
  const userLevel = RoleLevels[user.role];
  if (!userLevel) return false;
  // Grab the target role level from RoleLevels
  const roleLevel = RoleLevels[role];
  if (!roleLevel) return false;

  // Return true/falsed based on the user's role being a higher level than the target role.
  return userLevel >= roleLevel;
}