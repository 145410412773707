
import { Box, Stack, Typography } from '@mui/material';
// routes
import { Suspense, lazy } from 'react';
import Iconify from '../../components/Iconify';
import { PATH_PAGE } from '../../routes/paths';
import Spinner from '../../components/Spinner';

// ----------------------------------------------------------------------

const MotivationIllustration = lazy(() => import('../../assets/illustration_motivation'));


const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Our Services',
    path: PATH_PAGE.ourServices.root,
    icon: <Iconify icon={'material-symbols:design-services-outline'} {...ICON_SIZE} />,
    children: [
      {
        items: [
          { title: 'Counseling Children', path: PATH_PAGE.ourServices.counselingChildren },
          { title: 'Counseling Teens', path: PATH_PAGE.ourServices.counselingTeens },
          { title: 'Counseling Adults', path: PATH_PAGE.ourServices.counselingAdults },
          { title: 'Marriage & Couples Counseling', path: PATH_PAGE.ourServices.counselingMarriageAndCouples },
          { title: 'Christian Counseling', path: PATH_PAGE.ourServices.counselingChristian },
          { title: 'Counseling Veterans', path: PATH_PAGE.ourServices.counselingVeterans },
        ],
      },
      {
        items: [
          { title: 'Custody, Placement, And Attachment', path: PATH_PAGE.ourServices.custodyPlacementAndAttachment },
          { title: 'Circle of Security Parenting®', path: PATH_PAGE.ourServices.circleOfSecurityParenting },
          { title: 'Neurosequential Model of Therapeutics®', path: PATH_PAGE.ourServices.neurosequentialModelOfTherapeutics },
          { title: 'School-Based Mental Health', path: PATH_PAGE.ourServices.schoolBasedMentalHealth },
          { title: 'Substance Abuse Services', path: PATH_PAGE.ourServices.substanceAbuseServices },
          { title: 'Presentations Available', path: PATH_PAGE.ourServices.presentationsAvailable },
        ],
      },
      {
        items: [
          {
            title: 'Insurances Accepted',
            path: PATH_PAGE.insurancesAccepted,
            info: (
              <Stack sx={{ display: 'initial !important', width: '100% !important' }}>
                <Typography variant='caption' textAlign="center" sx={{ width: '100%' }}><strong>Insurances Accepted</strong></Typography>
                <Box
                  whileTap="tap"
                  whileHover="hover"
                  variants={{
                    hover: { scale: 1.02 },
                    tap: { scale: 0.98 },
                  }}
                >
                  <Suspense fallback={<Spinner/>}>
                    <MotivationIllustration />
                  </Suspense>
                </Box>
              </Stack>
            )
          },
        ],
      },
    ],
  },
  {
    title: 'Trainings & Workshops',
    icon: <Iconify icon={'healthicons:i-training-class'} {...ICON_SIZE} />,
    path: PATH_PAGE.trainingsAndWorkshops,
  },
  {
    title: 'Courses',
    icon: <Iconify icon={'carbon:course'} {...ICON_SIZE} />,
    path: PATH_PAGE.courses,
  },
  {
    title: 'About',
    icon: <Iconify icon={'mdi:about'} {...ICON_SIZE} />,
    path: PATH_PAGE.about,
  },
  {
    title: 'Contact',
    icon: <Iconify icon={'eva:phone-fill'} {...ICON_SIZE} />,
    path: PATH_PAGE.contact,
  },
  {
    title: 'Client Portal',
    icon: <Iconify icon={'material-symbols:physical-therapy'} {...ICON_SIZE} />,
    path: PATH_PAGE.clientPortal,
  },
];

export default menuConfig;
