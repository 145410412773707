import { Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import DashboardLayout from '../layouts/dashboard';
import MainLayout from '../layouts/main';
// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router({ settingsOpen, setSettingsOpen, onlineUsersOpen, setOnlineUsersOpen }) {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: 'register-referral',
          element: (
            <GuestGuard>
              <RegisterReferral />
            </GuestGuard>
          ),
        },
        { path: 'forgot-password', element: <ResetPassword /> },
        { path: 'reset-password/:token', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
            <DashboardLayout
              settingsOpen={settingsOpen}
              setSettingsOpen={setSettingsOpen}
              onlineUsersOpen={onlineUsersOpen}
              setOnlineUsersOpen={setOnlineUsersOpen}
            />
        </AuthGuard>
      ),
      children: [
        {
          path: 'home',
          element: <DashboardHome />
        },
        // {
        //   path: 'home/calendar',
        //   element: <Calendar />,
        // },
        {
          path: 'courses',
          element: <Courses />,
        },
        {
          path: 'changelog',
          element: <Changelog />,
        },
        // {
        //   path: 'home/paperwork',
        //   element: <Paperwork />,
        // },
        {
          path: 'developer',
          children: [
            { element: <Navigate to="/developer/administration" replace />, index: true },
            { path: 'kanban', element: <RoleBasedGuard accessibleRoles={["developer"]}><Kanban /></RoleBasedGuard> },
          ],
        },
        {
          path: 'admin',
          children: [
            { element: <Navigate to="/admin/overview" replace />, index: true },
            { path: 'overview', element: <RoleBasedGuard accessibleRoles={["developer", "manager", "admin"]}><AdminOverview /></RoleBasedGuard> },
            { path: 'management', element: <RoleBasedGuard accessibleRoles={["developer", "manager", "admin"]}><AdminManagement /></RoleBasedGuard> },
            { path: 'management/:tab', element: <RoleBasedGuard accessibleRoles={["developer", "manager", "admin"]}><AdminManagement /></RoleBasedGuard> },
            { path: 'management/:tab/:form', element: <RoleBasedGuard accessibleRoles={["developer", "manager", "admin"]}><AdminManagement /></RoleBasedGuard> },
            { path: 'custom-form-responses', element: <RoleBasedGuard accessibleRoles={["developer", "manager", "admin"]}><AdminManagement /></RoleBasedGuard> },
            { path: 'course-factory', element: <RoleBasedGuard accessibleRoles={["developer", "manager", "admin"]}><CourseFactory /></RoleBasedGuard> },
            { path: 'reports', element: <RoleBasedGuard accessibleRoles={["developer", "manager", "admin"]}><Reports /></RoleBasedGuard> },
            { path: 'file-manager', element: <RoleBasedGuard accessibleRoles={["developer", "admin"]}><FileManager /></RoleBasedGuard> },
            { path: 'administration', element: <RoleBasedGuard accessibleRoles={["developer", "admin"]}><ServerAdministration /></RoleBasedGuard> },
          ],
        },
        {
          path: 'referral',
          children: [
            { element: <Navigate to="/referral/my-referrals" replace />, index: true },
            { path: 'my-referrals', element: <RoleBasedGuard accessibleRoles={["developer", "admin", "referral"]}><ReferralMyReferrals /></RoleBasedGuard> },
            { path: 'create-referral', element: <RoleBasedGuard accessibleRoles={["developer", "admin", "manager", "referral", "employee"]}><ReferralCreateReferral /></RoleBasedGuard> },
          ],
        },
        {
          path: 'employee',
          children: [
            { element: <Navigate to="/employee/submissions" replace />, index: true },
            { path: 'submissions', element: <RoleBasedGuard accessibleRoles={["developer", "manager", "employee"]}><EmployeeSubmissions /></RoleBasedGuard> },
            { path: 'submissions/:tab', element: <RoleBasedGuard accessibleRoles={["developer", "manager", "employee"]}><EmployeeSubmissions /></RoleBasedGuard> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            // { path: 'profile', element: <UserProfile /> },
            { path: 'account', element: <RoleBasedGuard accessibleRoles={["developer", "admin", "manager", "referral", "employee", "user"]}><UserAccount /></RoleBasedGuard> },
            // { path: ':id', element: <UserProfile /> },
            // { path: 'mail', element: <Mail /> },
          ],
        },
        {
          path: 'form',
          children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            // { path: 'profile', element: <UserProfile /> },
            { path: 'information-request/:id', element: <RoleBasedGuard accessibleRoles={["developer", "admin", "manager", "employee"]}><InformationRequestViewer /></RoleBasedGuard> },
            { path: 'appointment-request/:id', element: <RoleBasedGuard accessibleRoles={["developer", "admin", "manager", "employee"]}><AppointmentRequestViewer /></RoleBasedGuard> },
            { path: 'referral/:id', element: <RoleBasedGuard accessibleRoles={["developer", "admin", "referral", "manager", "employee"]}><ReferralViewer /></RoleBasedGuard> },
            { path: 'custom/:submissionId', element: <RoleBasedGuard accessibleRoles={["developer", "admin", "manager", "referral", "employee"]}><CustomFormViewer /></RoleBasedGuard> },
            // { path: ':id', element: <UserProfile /> },
            // { path: 'mail', element: <Mail /> },
          ],
        },
        {
          path: '',
          element: <Navigate to="/dashboard/user/account" replace />,
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'contact-us/information-request', element: <Contact /> },
        { path: 'contact-us/appointment-request', element: <Contact /> },
        { path: 'client-portal-and-paperwork', element: <ClientPortalAndPaperwork /> },
        { path: 'trainings-and-workshops', element: <TrainingsAndWorkshops /> },
        { path: 'courses', element: <HomeCourses /> },
        { path: 'form/custom/:id', element: <FormResponderPage /> },
        { path: 'blog/post/:id', element: <HomeBlogPost /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        { path: 'terms-of-use', element: <TermsOfUse /> },
        { path: 'insurances-accepted', element: <InsurancesAccepted /> },
        { path: 'treatment-approaches-available', element: <TreatmentApproachesAvailable /> },
        {
          path: 'our-services',
          children: [
            { path: 'counseling-children', element: <CounselingChildren /> },
            { path: 'counseling-teens', element: <CounselingTeens /> },
            { path: 'counseling-adults', element: <CounselingAdults /> },
            { path: 'counseling-marriage-and-couples', element: <CounselingMarriageAndCouples /> },
            { path: 'counseling-christian', element: <CounselingChristian /> },
            { path: 'counseling-veterans', element: <CounselingVeterans /> },
            { path: 'custody-placement-and-attachment', element: <CustodyPlacementAndAttachment /> },
            { path: 'circle-of-security-parenting', element: <CircleOfSecurityParenting /> },
            { path: 'neurosequential-model-of-theraputics', element: <NeurosequentialModelOfTherapeutics /> },
            { path: 'school-based-mental-health', element: <SchoolBasedMentalHealth /> },
            { path: 'substance-abuse-services', element: <SubstanceAbuseServices /> },
            { path: 'presentations-available', element: <PresentationsAvailable /> },
            { path: 'learn-more' }
          ]
        }
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

function wait(time) {
  return new Promise(resolve => {
    setTimeout(resolve, time)
  })
}

// IMPORT COMPONENTS

// Main
// ----------------------------------------------------------------------
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
const TermsOfUse = Loadable(lazy(() => import('../pages/TermsOfUse'))); // eslint-disable-line
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const InsurancesAccepted = Loadable(lazy(() => import('../pages/InsuranceAccepted')));
const TreatmentApproachesAvailable = Loadable(lazy(() => import('../pages/TreatmentApproachesAvailable')));

const CounselingChildren = Loadable(lazy(() => import('../pages/our-services/CounselingChildren')));
const CounselingTeens = Loadable(lazy(() => import('../pages/our-services/CounselingTeens')));
const CounselingAdults = Loadable(lazy(() => import('../pages/our-services/CounselingAdults')));
const CounselingMarriageAndCouples = Loadable(lazy(() => import('../pages/our-services/CounselingMarriageAndCouples')));
const CounselingChristian = Loadable(lazy(() => import('../pages/our-services/CounselingChristian')));
const CustodyPlacementAndAttachment = Loadable(lazy(() => import('../pages/our-services/CustodyPlacementAndAttachment')));
const CircleOfSecurityParenting = Loadable(lazy(() => import('../pages/our-services/CircleOfSecurityParenting')));
const NeurosequentialModelOfTherapeutics = Loadable(lazy(() => import('../pages/our-services/NeurosequentialModelOfTherapeutics')));
const SchoolBasedMentalHealth = Loadable(lazy(() => import('../pages/our-services/SchoolBasedMentalHealth')));
const PresentationsAvailable = Loadable(lazy(() => import('../pages/our-services/PresentationsAvailable')));
const CounselingVeterans = Loadable(lazy(() => import('../pages/our-services/CounselingVeterans')));
const SubstanceAbuseServices = Loadable(lazy(() => import('../pages/our-services/SubstanceAbuseServices')));


const ClientPortalAndPaperwork = Loadable(lazy(() => import('../pages/ClientPortalAndPaperwork')));
const TrainingsAndWorkshops = Loadable(lazy(() => import('../pages/TrainingsAndWorkshops')));
const HomeCourses = Loadable(lazy(() => import('../pages/Courses')));
const HomeBlogPost = Loadable(lazy(() => import('../pages/BlogPostPage')));

// Authentication
// ----------------------------------------------------------------------
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const RegisterReferral = Loadable(lazy(() => import('../pages/auth/RegisterReferral')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));


// Dashboard
// ----------------------------------------------------------------------


// Home
const DashboardHome = Loadable(lazy(() => import('../pages/dashboard/Home')))
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Changelog = Loadable(lazy(() => import('../pages/dashboard/Changelog')));
const Courses = Loadable(lazy(() => import('../pages/dashboard/Courses')));
const Paperwork = Loadable(lazy(() => import('../pages/dashboard/Paperwork')));

// My Account
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail'))); // messages
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount'))); // settings
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile'))); // not sure if this will be used

// Developer
const ServerAdministration = Loadable(lazy(() => import('../pages/dashboard/developer/ServerAdministration')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/developer/Kanban')));

// Admin
const AdminOverview = Loadable(lazy(() => import('../pages/dashboard/admin/Overview')));
const AdminManagement = Loadable(lazy(() => import('../pages/dashboard/admin/Management')));
const CourseFactory = Loadable(lazy(() => import('../pages/dashboard/admin/CourseFactory')))
const Reports = Loadable(lazy(() => import('../pages/dashboard/admin/Reports')))
const FileManager = Loadable(lazy(() => import('../pages/dashboard/admin/FileManager')))

// Referral
const ReferralMyReferrals = Loadable(lazy(() => import('../pages/dashboard/referral/MyReferrals')));
const ReferralCreateReferral = Loadable(lazy(() => import('../pages/dashboard/referral/CreateReferral')));

// Employee
const EmployeeSubmissions = Loadable(lazy(() => import('../pages/dashboard/employee/Submissions')));

// Form
const CustomFormViewer = Loadable(lazy(() => import('../pages/dashboard/form/CustomFormViewer')));
const FormResponderPage = Loadable(lazy(() => import('../pages/dashboard/form/FormResponder')));
const ReferralViewer = Loadable(lazy(() => import('../pages/dashboard/form/ReferralViewer')));
const InformationRequestViewer = Loadable(lazy(() => import('../pages/dashboard/form/InformationRequestViewer')));
const AppointmentRequestViewer = Loadable(lazy(() => import('../pages/dashboard/form/AppointmentRequestViewer')));
