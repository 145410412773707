// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  registerReferral: path(ROOTS_AUTH, '/register-referral'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  resetPassword: path(ROOTS_AUTH, '/reset-password/:token'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  clientPortal: '/client-portal-and-paperwork',
  ourServices: {
    root: '/our-services',
    counselingChildren: '/our-services/counseling-children',
    counselingTeens: '/our-services/counseling-teens',
    counselingAdults: '/our-services/counseling-adults',
    counselingMarriageAndCouples: '/our-services/counseling-marriage-and-couples',
    counselingChristian: '/our-services/counseling-christian',
    counselingVeterans: '/our-services/counseling-veterans',
    custodyPlacementAndAttachment: '/our-services/custody-placement-and-attachment',
    circleOfSecurityParenting: '/our-services/circle-of-security-parenting',
    neurosequentialModelOfTherapeutics: '/our-services/neurosequential-model-of-theraputics',
    schoolBasedMentalHealth: '/our-services/school-based-mental-health',
    substanceAbuseServices: '/our-services/substance-abuse-services',
    presentationsAvailable: '/our-services/presentations-available',
  },
  form: {
    custom: '/form/custom/:id',
  },
  blogPostRoot: '/blog/post/',
  blogPost: '/blog/post/:id',
  trainingsAndWorkshops: '/trainings-and-workshops',
  courses: '/courses',
  insurancesAccepted: '/insurances-accepted',
  treatmentApproachesAvailable: '/treatment-approaches-available',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  // pricing: '/pricing',
  // payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  contactInformationRequest: '/contact-us/information-request',
  contactAppointmentRequest: '/contact-us/appointment-request',
  privacyPolicy: '/privacy-policy',
  termsOfUse: '/terms-of-use',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  changelog: path(ROOTS_DASHBOARD, '/changelog'),
  home: {
    root: path(ROOTS_DASHBOARD, '/home'),
    calendar: path(ROOTS_DASHBOARD, '/calendar'),
    courses: path(ROOTS_DASHBOARD, '/courses'),
  },
  developer: {
    root: path(ROOTS_DASHBOARD, '/developer'),
    kanban: path(ROOTS_DASHBOARD, '/developer/kanban'),
  },
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
    overview: path(ROOTS_DASHBOARD, '/admin/overview'),
    management: path(ROOTS_DASHBOARD, '/admin/management'),
    courseFactory: path(ROOTS_DASHBOARD, '/admin/course-factory'),
    reports: path(ROOTS_DASHBOARD, '/admin/reports'),
    fileManager: path(ROOTS_DASHBOARD, '/admin/file-manager'),
    administration: path(ROOTS_DASHBOARD, '/admin/administration'),
  },
  referral: {
    root: path(ROOTS_DASHBOARD, '/referral'),
    myReferrals: path(ROOTS_DASHBOARD, '/referral/my-referrals'),
    createReferral: path(ROOTS_DASHBOARD, '/referral/create-referral'),
  },
  employee: {
    root: path(ROOTS_DASHBOARD, '/employee'),
    submissions: path(ROOTS_DASHBOARD, '/employee/submissions'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    // profile: path(ROOTS_DASHBOARD, '/user/profile'),
    // user: path(ROOTS_DASHBOARD, '/user/:id'),
    mail: path(ROOTS_DASHBOARD, '/user/mail'),
  },
  form: {
    root: path(ROOTS_DASHBOARD, '/form'),
    informationRequest: path(ROOTS_DASHBOARD, '/form/information-request/:id'),
    appointmentRequest: path(ROOTS_DASHBOARD, '/form/appointment-request/:id'),
    referral: path(ROOTS_DASHBOARD, '/form/referral/:id'),
    custom: path(ROOTS_DASHBOARD, '/form/custom/:submissionId'),
  }
};
