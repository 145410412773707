import { PATH_AUTH, PATH_DASHBOARD, PATH_PAGE } from "./paths";

export const PUBLIC_DIRECTORY = [
    // AUTH Paths
    {
      label: 'Login',
      path: PATH_AUTH.login,
      keywords: ["Login", "Sign In", "Authenticate"],
      description: "User login page for accessing your account.",
      category: "Authentication",
    },
    {
      label: 'Register',
      path: PATH_AUTH.register,
      keywords: ["Register", "Sign Up", "Create Account"],
      description: "Page for registering a new user account.",
      category: "Authentication",
    },
    {
      label: 'Register Referral',
      path: PATH_AUTH.registerReferral,
      keywords: ["Register", "Referral", "Sign Up", "Create Account"],
      description: "Page for registering a new referral account.",
      category: "Authentication",
    },
    {
      label: 'Forgot Password',
      path: PATH_AUTH.forgotPassword,
      keywords: ["Password", "Forgot Password", "Reset Password", "Account Recovery"],
      description: "Page for recovering a forgotten password.",
      category: "Authentication",
    },
    
    // PAGE Paths
    {
      label: 'Client Portal',
      path: PATH_PAGE.clientPortal,
      keywords: ["Client", "Portal", "Providers", "Paperwork"],
      description: "Directory for clients to access paperwork and other resources.",
      category: "Client Services",
    },
    {
      label: 'Counseling Children',
      path: PATH_PAGE.ourServices.counselingChildren,
      keywords: ["Counseling", "Children"],
      description: "Information on counseling services for children.",
      category: "Services",
    },
    {
      label: 'Counseling Teens',
      path: PATH_PAGE.ourServices.counselingTeens,
      keywords: ["Counseling", "Teens"],
      description: "Information on counseling services for teenagers.",
      category: "Services",
    },
    {
      label: 'Counseling Adults',
      path: PATH_PAGE.ourServices.counselingAdults,
      keywords: ["Counseling", "Adults"],
      description: "Information on counseling services for adults.",
      category: "Services",
    },
    {
      label: 'Counseling Marriage and Couples',
      path: PATH_PAGE.ourServices.counselingMarriageAndCouples,
      keywords: ["Counseling", "Marriage", "Couples"],
      description: "Information on counseling services for marriage and couples.",
      category: "Services",
    },
    {
      label: 'Counseling Christian',
      path: PATH_PAGE.ourServices.counselingChristian,
      keywords: ["Counseling", "Christian"],
      description: "Information on Christian counseling services.",
      category: "Services",
    },
    {
      label: 'Counseling Veterans',
      path: PATH_PAGE.ourServices.counselingVeterans,
      keywords: ["Counseling", "Veterans"],
      description: "Information on counseling services for veterans.",
      category: "Services",
    },
    {
      label: 'Custody Placement and Attachment',
      path: PATH_PAGE.ourServices.custodyPlacementAndAttachment,
      keywords: ["Custody", "Placement", "Attachment"],
      description: "Information on custody placement and attachment services.",
      category: "Services",
    },
    {
      label: 'Circle of Security Parenting',
      path: PATH_PAGE.ourServices.circleOfSecurityParenting,
      keywords: ["Parenting", "Circle of Security"],
      description: "Information on Circle of Security Parenting services.",
      category: "Services",
    },
    {
      label: 'Neurosequential Model of Therapeutics',
      path: PATH_PAGE.ourServices.neurosequentialModelOfTherapeutics,
      keywords: ["Neurosequential", "Therapeutics"],
      description: "Information on Neurosequential Model of Therapeutics.",
      category: "Services",
    },
    {
      label: 'School-Based Mental Health',
      path: PATH_PAGE.ourServices.schoolBasedMentalHealth,
      keywords: ["School-Based", "Mental Health"],
      description: "Information on school-based mental health services.",
      category: "Services",
    },
    {
      label: 'Substance Abuse Services',
      path: PATH_PAGE.ourServices.substanceAbuseServices,
      keywords: ["Substance Abuse", "Services"],
      description: "Information on substance abuse services.",
      category: "Services",
    },
    {
      label: 'Presentations Available',
      path: PATH_PAGE.ourServices.presentationsAvailable,
      keywords: ["Presentations"],
      description: "Information on available presentations.",
      category: "Services",
    },
    {
      label: 'Trainings and Workshops',
      path: PATH_PAGE.trainingsAndWorkshops,
      keywords: ["Trainings", "Workshops"],
      description: "Information on available trainings and workshops.",
      category: "Services",
      tags: ["trainings", "workshops"],
    },
    {
      label: 'Courses',
      path: PATH_PAGE.courses,
      keywords: ["Courses", "Learning"],
      description: "List of available courses.",
      category: "Education",
    },
    {
      label: 'About Us',
      path: PATH_PAGE.about,
      keywords: ["About", "Company Information", "Metrics", "Outcomes", "Team", "Employee", "Employees", "Job", "Jobs", "Employment", "Reviews"],
      description: "Page with information about the company.",
      category: "Company",
    },
    {
      label: 'Contact Us',
      path: PATH_PAGE.contact,
      keywords: ["Contact Us", "Get in Touch", "Contact", "Address", "Location"],
      description: "Page with contact information and form.",
      category: "Company",
    },
    {
      label: 'Information Request',
      path: PATH_PAGE.contactInformationRequest,
      keywords: ["Information", "Request", "Contact"],
      description: "Form for requesting contact information.",
      category: "Company",
    },
    {
      label: 'Appointment Request',
      path: PATH_PAGE.contactAppointmentRequest,
      keywords: ["Appointment", "Request"],
      description: "Form for requesting an appointment.",
      category: "Company",
    },
    {
      label: 'Privacy Policy',
      path: PATH_PAGE.privacyPolicy,
      keywords: ["Privacy Policy"],
      description: "Page with privacy policy information.",
      category: "Legal",
    },
    {
      label: 'Terms of Use',
      path: PATH_PAGE.termsOfUse,
      keywords: ["Terms of Use"],
      description: "Page with terms of use information.",
      category: "Legal",
    },
    {
      label: 'Insurances Accepted',
      path: PATH_PAGE.insurancesAccepted,
      keywords: ["Insurances", "Accepted"],
      description: "List of accepted insurances and their networks",
      category: "Services",
    },
    
    // DASHBOARD Paths (Restricted Access)
    // {
    //   label: 'Dashboard Home',
    //   path: PATH_DASHBOARD.home.root,
    //   keywords: ["Home", "Dashboard"],
    //   description: "Dashboard home page.",
    //   category: "Dashboard",
    //   tags: ["home", "dashboard"],
    // },
    // {
    //   label: 'Dashboard Courses',
    //   path: PATH_DASHBOARD.home.courses,
    //   keywords: ["Courses", "Dashboard"],
    //   description: "Dashboard courses page.",
    //   category: "Dashboard",
    //   tags: ["courses", "dashboard"],
    // },
    // {
    //   label: 'Developer Kanban',
    //   path: PATH_DASHBOARD.developer.kanban,
    //   keywords: ["Kanban", "Developer"],
    //   description: "Developer Kanban board.",
    //   category: "Dashboard",
    //   tags: ["kanban", "developer"],
    //   requiredRole: "developer"
    // },
    // {
    //   label: 'Admin Overview',
    //   path: PATH_DASHBOARD.admin.overview,
    //   keywords: ["Overview", "Admin"],
    //   description: "Admin dashboard overview.",
    //   category: "Dashboard",
    //   tags: ["overview", "admin"],
    //   requiredRole: "admin"
    // },
    // {
    //   label: 'Admin Management',
    //   path: PATH_DASHBOARD.admin.management,
    //   keywords: ["Management", "Admin"],
    //   description: "Admin management page.",
    //   category: "Dashboard",
    //   tags: ["management", "admin"],
    //   requiredRole: "admin"
    // },
    // {
    //   label: 'Admin Course Factory',
    //   path: PATH_DASHBOARD.admin.courseFactory,
    //   keywords: ["Course Factory", "Admin"],
    //   description: "Admin course factory page.",
    //   category: "Dashboard",
    //   tags: ["course factory", "admin"],
    //   requiredRole: "admin"
    // },
    // {
    //   label: 'Admin Reports',
    //   path: PATH_DASHBOARD.admin.reports,
    //   keywords: ["Reports", "Admin"],
    //   description: "Admin reports page.",
    //   category: "Dashboard",
    //   tags: ["reports", "admin"],
    //   requiredRole: "admin"
    // },
    // {
    //   label: 'Admin File Manager',
    //   path: PATH_DASHBOARD.admin.fileManager,
    //   keywords: ["File Manager", "Admin"],
    //   description: "Admin file manager page.",
    //   category: "Dashboard",
    //   tags: ["file manager", "admin"],
    //   requiredRole: "admin"
    // },
    // {
    //   label: 'Admin Administration',
    //   path: PATH_DASHBOARD.admin.administration,
    //   keywords: ["Administration", "Admin"],
    //   description: "Admin administration page.",
    //   category: "Dashboard",
    //   tags: ["administration", "admin"],
    //   requiredRole: "admin"
    // },
    // {
    //   label: 'My Referrals',
    //   path: PATH_DASHBOARD.referral.myReferrals,
    //   keywords: ["My Referrals", "Referral"],
    //   description: "Page for managing your referrals.",
    //   category: "Dashboard",
    //   tags: ["my referrals", "referral"],
    //   requiredRole: "referral"
    // },
    // {
    //   label: 'Create Referral',
    //   path: PATH_DASHBOARD.referral.createReferral,
    //   keywords: ["Create Referral", "Referral"],
    //   description: "Page for creating a new referral.",
    //   category: "Dashboard",
    //   tags: ["create referral", "referral"],
    //   requiredRole: "referral"
    // },
    // {
    //   label: 'Employee Submissions',
    //   path: PATH_DASHBOARD.employee.submissions,
    //   keywords: ["Submissions", "Employee"],
    //   description: "Page for employee submissions.",
    //   category: "Dashboard",
    //   tags: ["submissions", "employee"],
    //   requiredRole: "employee"
    // },
    // {
    //   label: 'User Account',
    //   path: PATH_DASHBOARD.user.account,
    //   keywords: ["Account", "User"],
    //   description: "Page for managing user account settings.",
    //   category: "Dashboard",
    //   tags: ["account", "user"],
    //   requiredRole: "user"
    // },
];


  