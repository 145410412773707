
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, Container, Stack, Typography } from '@mui/material';
// components
import Logo from '../../components/Logo';

//
import Iconify from '../../components/Iconify';
import useSocket from '../../hooks/useSocket';
import { PATH_PAGE } from '../../routes/paths';
import MainHeader from './MainHeader';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  const { socket } = useSocket();
  const navigate = useNavigate();

  const isHome = pathname === '/';

  useEffect(() => {
    if (socket) {
      socket.emit('change_page', { path: pathname })
    }
  }, [pathname]);

  return (
    <div className='noScroll'>
      <Stack sx={{ minHeight: 1 }}>
        <MainHeader />

        <Outlet />

        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            py: 4,
            // pt: 10,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default',
          }}
        >
          <Container>
            <Logo sx={{ mb: 1, mx: 'auto', width: 50, height: 50 }} />

            <Typography variant="caption" component="p">
              Copyright © Stein Counseling & Consulting Services - All rights reserved
            </Typography>
            <Typography variant="caption" component="p">
              Proverbs 11:3 "The integrity of the upright guides them."
            </Typography>

            <Stack direction="row" justifyContent="center" sx={{ mt: 1 }} spacing={1}>
              <Button onClick={() => navigate(PATH_PAGE.contactAppointmentRequest)} size='small' sx={{py: .25, backgroundColor: 'transparent !important'}} startIcon={<Iconify icon={"icon-park-outline:appointment"}/>}>
                Request an appointment
              </Button>
              <Button onClick={() => navigate(PATH_PAGE.contactInformationRequest)} size='small' sx={{py: .25, backgroundColor: 'transparent !important'}} startIcon={<Iconify icon={"fluent:book-information-20-filled"}/>}>
                Get more information
              </Button>
              <Button onClick={() => window.open(`tel:608-785-7000`)} size='small' sx={{py: .25, backgroundColor: 'transparent !important'}} startIcon={<Iconify icon={"fluent:call-20-filled"}/>}>
                Call Now
              </Button>
            </Stack>
            <Stack direction="row" justifyContent="center" sx={{ mt: 1 }} spacing={1}>
              <img
                src="https://i.imgur.com/iz93qtH.png"
                alt="compliassure"
                style={{
                  width: 124,
                  backgroundColor: 'white',
                  borderRadius: 8
                }}
              />
            </Stack>
          </Container>
        </Box>
      </Stack>
    </div>
  );
}
